<template>
  <div class>
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col w-full">
        <span><b>Number : </b>{{ selected.code }}</span>
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col w-full">
      <b>Journal Name : </b>{{ selected.document }}
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col w-full">
      <b>Tax Number : </b>{{ selected.tax_invoice_number }}
      </div>
    </div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.transaction">
          <vs-td style="align-item-center">{{ tr.operating_unit }}</vs-td>
          <vs-td style="align-item-center">{{ tr.cost_center }}</vs-td>
          <vs-td style="align-item-center">{{ tr.data.ref_code }}</vs-td>
          <vs-td style="align-item-left">{{ tr.data.coa_code }}</vs-td>
          <vs-td style="align-item-center">{{ tr.data.coa_name }}</vs-td>
          <vs-td style="text-align:right">
            <br />{{ tr.data.type == 'D' ? priceFormat(tr.data.value) : 0 }}
          </vs-td>
          <vs-td style="text-align:right">
            <br />{{ tr.data.type == 'C' ? priceFormat(tr.data.value) : 0 }}
          </vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td style="align-item-left"></vs-td>
          <vs-td style="align-item-center"></vs-td>
          <vs-td style="align-item-center"></vs-td>
          <vs-td style="align-item-center"></vs-td>
          <vs-td style="align-item-center"></vs-td>
          <vs-td style="text-align:right">
            <br />{{priceFormat(total_debit)}}
          </vs-td>
          <vs-td style="text-align:right">
            <br />{{priceFormat(total_credit)}}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  components: {},
  props: {
    dataId:Number,
    selected: {},
  },
  watch: {
    dataId() {
      this.reloadData(this.params);
    },
  },
  data: () => ({
    params: {
      search: "",
      length: 100,
      page: 1,
    },
    header: [
      {
        text: "Operating Unit",
        value: "operating_unit_code",
        // width: '5%'
      },
      {
        text: "Cost Center",
        value: "cost_center",
        // width: '5%'
      },
      {
        text: "Reference",
        value: "ref_code",
        // width: '5%'
      },
      {
        text: "Account",
        value: "coa_code",
        //width: '15%'
      },
      {
        text: "Label",
        value: "coa_name",
        // width: '5%'
      },
      {
        text: "Debit",
        value: "value",
        // width: '5%'
      },
      {
        text: "Credit",
        value: "value",
        // width: '5%'
      },
    ],
    responseData: {},
    total_debit: 0,
    total_credit: 0,
  }),
  methods: {
    dateFormat(date){
      return moment(date).format('DD-MM-YYYY HH:mm')
    },
    reloadData(params) {
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/report/journal/" + this.dataId, {
          params: {
            search: this.params.search,
            length: this.params.length,
            page: this.params.page,
            order: this.params.order,
            sort: this.params.sort,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            console.log("resp.data",resp.data)
            console.log("responseData.transaction", resp.data.transaction)
            this.responseData = resp.data;
            this.total_debit = resp.data.total_debit;
            this.total_credit = resp.data.total_credit;
          }
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style scoped>
.margin-btm {
  margin-bottom: 1rem !important;
}
.vs-input--input.normal {
  text-align: end;
}
</style>