<template>
  <vx-card title="Journal">
    <div class="vx-row mb-6" style="width:50%">
      <div class="vx-col sm:w-1/4 w-full flex items-center">
        <span>Operating Unit</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <multiselect
          v-model="operatingUnit.selected"
          :options="operatingUnit.options"
          :multiple="false"
          :allow-empty="false"
          :max-height="160"
          placeholder=" Type to search"
          track-by="TerritoryID"
          label="Name"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{dt.option.Name}} - {{ dt.option.TerritoryName }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{dt.option.Name}} - {{ dt.option.TerritoryName }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card>
            <vs-tabs :color="colorx">
                <vs-tab @click="colorx = 'success'" label="Failed">
                    <div class="con-tab-ejemplo">
                    <failed :operatingUnit="operatingUnit"></failed>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Sync">
                    <sync :operatingUnit="operatingUnit"></sync>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Success">
                    <success :operatingUnit="operatingUnit"></success>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="All">
                    <all :operatingUnit="operatingUnit"></all>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Export">
                    <download></download>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
  </vx-card>
</template>

<script>
import failed from "./failed.vue";
import sync from "./sync.vue";
import success from "./success.vue";
import all from "./all.vue";
import download from "./export.vue";

export default {
    components: {
        'failed': failed,
        'sync': sync,
        'success': success,
        'all': all,
        'download': download,
    },
    data: () => ({
        colorx: "success",
        operatingUnit : {
          selected: null,
          options: [],
        },
    }),
    mounted() {
        this.getOperatingUnit();
    },
    methods: {
        getOperatingUnit() {
        this.$vs.loading();
        this.$http
            .get("/api/v1/master/operating-unit-with-territory", {
            params: {
                order: "name",
                sort: "asc",
            },
            })
            .then((resp) => {
            if (resp.status == "success") {
                var options = [];
                options.push({
                ID: 0,
                Name: "All"
                });
                resp.data.records.forEach(function(item) {
                options.push(item);
                }, options);
                this.operatingUnit.options = options;
                this.operatingUnit.selected = this.operatingUnit.options[0];
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
            });
        },
    },
};
</script>
 
<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>